export default {
    header: [
    {
        sortable: false,
        text: "Date",
        value:"payment_date",
    },
    {
        sortable: false,
        text: "Case id",
        value:"case",
    },
    {
        sortable: false,
        text: "Mode of Payment",
        value:"mode",
    },
    {
        sortable: false,
        text: "Amount",
        value:"amount",
    },
    {
        sortable: false,
        text: "Status",
        value:"status",
    },
    {
        sortable: false,
        text: "Remarks",
        value:"remarks",
    },
],
}