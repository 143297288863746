const commonStartHeaders = [
  {
    sortable: false,
    text: "Date",
    value: "created",
    width: 120,
  },
  {
    sortable: false,
    text: "Name",
    width: 140,
    value: "name",
  },
  {
    sortable: false,
    text: "Contact",
    value: "mobile",
  },
  {
    sortable: false,
    text: "Source",
    value: "source",
  },
  {
    sortable: false,
    text: "Remark",
    value: "remark",
  },
];

const commonEndHeaders = [
  {
    sortable: false,
    text: "Assigned Sales",
    width: 170,
    value: "assigned_sales",
  },
  {
    sortable: false,
    text: "Actions",
    width: 160,
    value: "action",
  },
];

export default {
  leads: [
    ...commonStartHeaders,
    {
      sortable: false,
      text: "Status",
      width: 120,
      value: "status_display",
    },
    ...commonEndHeaders,
  ],

  assigned: [
    ...commonStartHeaders,
    {
      sortable: false,
      text: "Status",
      width: 150,
      value: "status_display",
    },
    ...commonEndHeaders,
  ],

  query: [
    ...commonStartHeaders,
    {
      sortable: false,
      text: "No. of Times Query Mail Sent",
      value: "query_mail_sent_count",
      width: 150,
    },
    ...commonEndHeaders,
  ],

  follow_up_carry_forward: [
    ...commonStartHeaders,
    {
      sortable: false,
      text: "Follow-up Date",
      value: "follow_up_date",
      width: 120,
    },
    {
      sortable: false,
      text: "Follow-up Reason",
      value: "follow_up_reason",
    },
    ...commonEndHeaders,
  ],

  irrelevant: [
    ...commonStartHeaders,
    {
      sortable: false,
      text: "Reason of Irrelevance",
      value: "irrelevance_reason",
    },
    ...commonEndHeaders,
  ],

  converted: [
    ...commonStartHeaders,
    {
      sortable: false,
      width: 120,
      text: "No. of Case",
      value: "case_count",
    },
    ...commonEndHeaders,
  ],

  case_detail: [
    {
      sortable: false,
      text: "Case id",
      value: "case_id",
    },
    {
      sortable: false,
      value: "date",
      text: "Date",
    },
    {
      sortable: false,
      text: "Service",
      value: "service",
    },
    {
      sortable: false,
      text: "University",
      value: "university",
    },
    {
      sortable: false,
      text: "ECA Body",
      value: "eca",
    },
    {
      sortable: false,
      text: "Total Quotation Amount",
      value: "total",
    },
    {
      sortable: false,
      text: "Status",
      value: "status",
    },
    {
      sortable: false,
      text: "Action",
      value: "action",
    },
  ],
};
